import {Injectable} from '@angular/core';
import {
    AbstractEntityRepositoryServices,
} from "@ypa/data-access/abstract-repository-services";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from 'rxjs';
import {environment} from "@ypa/constants/environments";
import {
    BaseUserDashboardMetricClubDefaultInterface,
    UserDashboardMetricClubDefaultInterface, UserDashboardMetricClubDefaultParams
} from "@ypa/types/user-dashboard-metric-club-default";
import {
    UserDashboardMetricClubDefaultRepository
} from "@ypa/state-management/shared/user-dashboard-metric-club-default";
import {EntityListInterface} from "@ypa/types/base-entity";
;

@Injectable({providedIn: "root"})

export class UserDashboardMetricClubDefaultService extends AbstractEntityRepositoryServices<UserDashboardMetricClubDefaultInterface, BaseUserDashboardMetricClubDefaultInterface, UserDashboardMetricClubDefaultParams> {
    constructor(
        private http: HttpClient,
        private userDashboardMetricClubDefaultRepository: UserDashboardMetricClubDefaultRepository
    ) {
        super(userDashboardMetricClubDefaultRepository);
    }

    protected createReq(form: Partial<UserDashboardMetricClubDefaultInterface> | BaseUserDashboardMetricClubDefaultInterface): Observable<UserDashboardMetricClubDefaultInterface> {
        return this.http.post<UserDashboardMetricClubDefaultInterface>(`${environment.apiUrl}/user-dashboard-metric-club-default`, form);
    }

    protected getAllReq(): Observable<UserDashboardMetricClubDefaultInterface[]> {
        return this.http.get<UserDashboardMetricClubDefaultInterface[]>(`${environment.apiUrl}/user-dashboard-metric-club-default`);
    }

    protected getByIdReq(id: number): Observable<UserDashboardMetricClubDefaultInterface> {
        return this.http.get<UserDashboardMetricClubDefaultInterface>(`${environment.apiUrl}/user-dashboard-metric-club-default/${id}`);
    }

    protected getByReq(params: UserDashboardMetricClubDefaultParams): Observable<UserDashboardMetricClubDefaultInterface[]> {
        return this.http.get<UserDashboardMetricClubDefaultInterface[]>(`${environment.apiUrl}/user-dashboard-metric-club-default`, {params: params as {}});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/user-dashboard-metric-club-default/${id}`);
    }

    protected updateReq(id: number, form: Partial<UserDashboardMetricClubDefaultInterface>): Observable<UserDashboardMetricClubDefaultInterface> {
        return throwError(() => new Error('Update form club default is not implemented'));
    }

    protected updateListReq(list: EntityListInterface<UserDashboardMetricClubDefaultInterface>): Observable<EntityListInterface<UserDashboardMetricClubDefaultInterface>> {
        throw new Error('Method not implemented.');
    }
}
