import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, switchMap, tap} from "rxjs";
import {filter} from "rxjs/operators";
import {SystemEventsService} from "@ypa/data-access/system-events";
import {CurrentClubService} from "@ypa/data-access/current-club";
import {AuthTokenRepository} from "@ypa/state-management/shared/auth-token";
import {UserAuthService} from "@ypa/data-access/user";
import {CalculationService} from "@ypa/data-access/calculation";
import {ClubService} from "@ypa/data-access/club";
import {CollectorService} from "@ypa/data-access/collector";
import {FormService} from "@ypa/data-access/form";
import {FormClubService} from "@ypa/data-access/form-club";
import {MetricHintService} from "@ypa/data-access/metric-hint";
import {MetricHintTextService} from "@ypa/data-access/metric-hint-text";
import {MetricService} from "@ypa/data-access/metric";
import {MetricReportService} from "@ypa/data-access/metric-report";
import {MetricClubService} from "@ypa/data-access/metric-club";
import {TasksService} from "@ypa/data-access/tasks";
import {RoleService} from "@ypa/data-access/role";
import {SportService} from "@ypa/data-access/sport";
import {TagService} from "@ypa/data-access/tag";
import {UserDashboardMetricService} from "@ypa/data-access/user-dashboard-metric";
import {UserDashboardMetricClubService} from "@ypa/data-access/user-dashboard-metric-club";
import {CoachDashboardService} from "@ypa/data-access/coach-dashboard";
import {CoachDashboardMetricService} from "@ypa/data-access/coach-dashboard-metric";
import {FormClubDefaultService} from "@ypa/data-access/form-club-default";
import {SportClubDefaultService} from "@ypa/data-access/sport-club-default";
import {MetricClubDefaultService} from "@ypa/data-access/metric-club-default";
import {UserDashboardMetricClubDefaultService} from "@ypa/data-access/user-dashboard-metric-club-default";
import {NavigationEnd, Router} from "@angular/router";
import {LanguageCurrentService, LanguageService} from "@ypa/data-access/language";
import { MixpanelAnalyticsService } from '@ypa/libs/analytics';

@Component({
    selector: 'backoffice-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

    private subscription = new Subscription();

    constructor(
        private systemEventsService: SystemEventsService,
        private currentClubService: CurrentClubService,
        private authTokenRepository: AuthTokenRepository,
        private userAuthService: UserAuthService,
        private calculationService: CalculationService,
        private clubService: ClubService,
        private collectorService: CollectorService,
        private formService: FormService,
        private formClubService: FormClubService,
        private metricHintService: MetricHintService,
        private metricHintTextService: MetricHintTextService,
        private metricService: MetricService,
        private metricReportService: MetricReportService,
        private metricClubService: MetricClubService,
        private tasksService: TasksService,
        private roleService: RoleService,
        private sportService: SportService,
        private tagService: TagService,
        private userDashboardMetricService: UserDashboardMetricService,
        private userDashboardMetricClubService: UserDashboardMetricClubService,
        private coachDashboardService: CoachDashboardService,
        private coachDashboardMetricService: CoachDashboardMetricService,
        private formClubDefaultService: FormClubDefaultService,
        private sportClubDefaultService: SportClubDefaultService,
        private metricClubDefaultService: MetricClubDefaultService,
        private userDashboardMetricClubDefaultService: UserDashboardMetricClubDefaultService,
        private router: Router,
        private languageCurrentService: LanguageCurrentService,
        private languageService: LanguageService,
        private mixpanelAnalyticsService: MixpanelAnalyticsService,
    ) {
    }

    ngOnInit() {
        this.handleLogoutEvent();
        this.setUserUiLanguage();
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe((event: NavigationEnd) => {
            // Call trackPageView with each new route
            this.mixpanelAnalyticsService.trackPageView('backoffice', event.urlAfterRedirects);
          });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private handleLogoutEvent() {
        this.subscription.add(
            this.systemEventsService.logout$.pipe(
                switchMap(() => this.userAuthService.logout()),
                // catchError(() => of(true))
            ).subscribe(() => {
                this.currentClubService.clear();
                this.authTokenRepository.clear();
                this.userAuthService.clear();
                this.calculationService.clear();
                this.clubService.clear();
                this.collectorService.clear();
                this.formService.clear();
                this.formClubService.clear();
                this.metricHintService.clear();
                this.metricHintTextService.clear();
                this.metricService.clear();
                this.metricReportService.clear();
                this.metricClubService.clear();
                this.tasksService.clear();
                this.roleService.clear();
                this.sportService.clear();
                this.tagService.clear();
                this.userDashboardMetricService.clear();
                this.userDashboardMetricClubService.clear();
                this.coachDashboardService.clear();
                this.coachDashboardMetricService.clear();
                this.formClubDefaultService.clear();
                this.metricClubDefaultService.clear();
                this.sportClubDefaultService.clear();
                this.userDashboardMetricClubDefaultService.clear();
                this.languageService.clear();
                this.mixpanelAnalyticsService.clearUserId();

                this.router.navigate(['/auth']).then();
            })
        );
    }

    private setUserUiLanguage() {
        const sub = this.userAuthService.data$.pipe(
            filter(user => !!user),
            tap((user) => {
                this.languageCurrentService.setCurrentLanguageByCode(user.uiLanguageId);
            }),
        ).subscribe();
        this.subscription.add(sub);
    }

}
