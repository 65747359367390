import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthTokenRepository } from '@ypa/state-management/shared/auth-token';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private authTokenRepository: AuthTokenRepository,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (!this.authTokenRepository.getAccessTokenSnapshot()) {
            this.router.navigate(['/auth']).then();
            return false;
        }

        return true;
    }
}
