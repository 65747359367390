import {Injectable} from '@angular/core';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {FormClubDefaultInterface, FormClubDefaultParams} from "@ypa/types/form-club-default";

const name = 'FORM_CLUB_DEFAULT';

@Injectable({
    providedIn: 'root'
})
export class FormClubDefaultRepository extends AbstractEntityRepository<FormClubDefaultInterface, FormClubDefaultParams> {
    protected readonly store = createStore(
        {name},
        withEntities<FormClubDefaultInterface>()
    );
}

