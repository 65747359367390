import {Injectable} from '@angular/core';
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {ControlsOf, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";
import {SportClubDefaultInterface} from "@ypa/types/sport-club-default";

@Injectable()
export class SportClubDefaultFormService extends AbstractFormService<SportClubDefaultInterface> {
    getForm(params: Partial<SportClubDefaultInterface>): FormGroup<ControlsOf<Partial<SportClubDefaultInterface>>> {

        let form = {};

        if (params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        return this.fb.group({
            ...form,
            ...{
                clubId: this.fb.control(params.clubId ? params.clubId : null, [Validators.required]),
                sportId: this.fb.control(params.sportId ? params.sportId : null, [Validators.required])
            }
        });
    }
}
