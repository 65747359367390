import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UserAuthService } from '@ypa/data-access/user';
import { AuthInterceptor } from '@ypa/interceptors/auth';
import { catchError, switchMap } from 'rxjs/operators';
import { of, take } from 'rxjs';
import { GuestGuard } from './guards/guest.guard';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import {
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatDialogConfig,
} from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { UserAuthRepository } from '@ypa/state-management/shared/auth';
import { IonicModule } from '@ionic/angular';
import { QuillModule } from 'ngx-quill';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { JsonLoaderService } from '@ypa/i18n';
import { DndModule } from 'ngx-drag-drop';
import { environment } from '@ypa/constants/environments';
import { LanguageCurrentService } from '@ypa/data-access/language';
import 'quill-emoji/dist/quill-emoji.js';
import { ANALYTICS_SERVICE_TOKEN, MixpanelAnalyticsService } from '@ypa/libs/analytics';

const richTextEditorToolbar = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ 'color': [] }],
    ['clean', 'link'], // remove formatting button
    ['emoji']
];

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./pages/login').then((m) => m.LoginModule),
        canActivate: [GuestGuard],
    },
    {
        path: 'onboarding',
        loadChildren: () =>
            import('./pages/onboarding/routes').then((r) => r.ROUTES),
        canActivate: [GuestGuard],
    },
    {
        path: 'sign-up',
        loadComponent: () => import('./pages/onboarding/components/sign-up-by-phone/sign-up-by-phone.component').then((c) => c.SignUpByPhoneComponent),
    },
    {
        path: 'sign-up/create-profile',
        loadComponent: () => import('./pages/onboarding/components/create-profile-by-phone/create-profile-by-phone.component').then((c) => c.CreateProfileByPhoneComponent),
    },
    {
        path: '',
        loadChildren: () =>
            import('./pages/dashboard').then((m) => m.DashboardModule),
        canActivate: [AuthenticatedGuard],
    },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
        BrowserAnimationsModule,
        HttpClientModule,
        IonicModule.forRoot(),
        MatSelectModule,
        MatInputModule,
        MatAutocompleteModule,
        DndModule,
        QuillModule.forRoot({
            modules: {
                toolbar: richTextEditorToolbar,
                // syntax: true,
                "emoji-toolbar": true,
                "emoji-textarea": false,
                "emoji-shortname": true,
            },
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: JsonLoaderService,
            },
            defaultLanguage: environment.defaultLanguage,
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (
                userAuthService: UserAuthService,
                userAuthRepository: UserAuthRepository,
                languageCurrentService: LanguageCurrentService
            ) => {
                return () =>
                    userAuthService.get(true).pipe(
                        take(1),
                        catchError(() => of(null)),
                        switchMap((userAuth) =>
                            languageCurrentService.getLanguageList().pipe(
                                take(1),
                                catchError(() => of(null)),
                                switchMap(() => of(userAuth))
                            )
                        )
                    );
            },
            deps: [UserAuthService, UserAuthRepository, LanguageCurrentService],
            multi: true,
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                ...new MatDialogConfig(),
                scrollStrategy: new NoopScrollStrategy(),
            } as MatDialogConfig,
        },
        AuthenticatedGuard,
        GuestGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: ANALYTICS_SERVICE_TOKEN, useClass: MixpanelAnalyticsService }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
