import {Injectable} from '@angular/core';
import {
    AbstractEntityRepositoryServices,
} from "@ypa/data-access/abstract-repository-services";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from 'rxjs';
import {environment} from "@ypa/constants/environments";
import {
    BaseMetricClubDefaultInterface,
    MetricClubDefaultInterface,
    MetricClubDefaultParams
} from "@ypa/types/metric-club-default";
import {MetricClubDefaultRepository} from "@ypa/state-management/shared/metric-club-default";
import {EntityListInterface} from "@ypa/types/base-entity";
;

@Injectable({providedIn: "root"})
export class MetricClubDefaultService extends AbstractEntityRepositoryServices<MetricClubDefaultInterface, BaseMetricClubDefaultInterface, MetricClubDefaultParams> {
    constructor(
        private http: HttpClient,
        private metricClubDefaultRepository: MetricClubDefaultRepository
    ) {
        super(metricClubDefaultRepository);
    }

    protected createReq(form: Partial<MetricClubDefaultInterface> | BaseMetricClubDefaultInterface): Observable<MetricClubDefaultInterface> {
        return this.http.post<MetricClubDefaultInterface>(`${environment.apiUrl}/metric-club-default`, form);
    }

    protected getAllReq(): Observable<MetricClubDefaultInterface[]> {
        return this.http.get<MetricClubDefaultInterface[]>(`${environment.apiUrl}/metric-club-default`);
    }

    protected getByIdReq(id: number): Observable<MetricClubDefaultInterface> {
        return this.http.get<MetricClubDefaultInterface>(`${environment.apiUrl}/metric-club-default/${id}`);
    }

    protected getByReq(params: MetricClubDefaultParams): Observable<MetricClubDefaultInterface[]> {
        return this.http.get<MetricClubDefaultInterface[]>(`${environment.apiUrl}/metric-club-default`, {params: params as {}});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/metric-club-default/${id}`);
    }

    protected updateReq(id: number, form: Partial<MetricClubDefaultInterface>): Observable<MetricClubDefaultInterface> {
        return throwError(() => new Error('Update form club default is not implemented'));
    }

    protected updateListReq(list: EntityListInterface<MetricClubDefaultInterface>): Observable<EntityListInterface<MetricClubDefaultInterface>> {
        throw new Error('Method not implemented.');
    }
}
