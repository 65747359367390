import {Injectable} from '@angular/core';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {SportClubDefaultInterface, SportClubDefaultParams} from "@ypa/types/sport-club-default";

const name = 'SPORT_CLUB_DEFAULT';

@Injectable({
    providedIn: 'root'
})
export class SportClubDefaultRepository extends AbstractEntityRepository<SportClubDefaultInterface, SportClubDefaultParams> {
    protected readonly store = createStore(
        {name},
        withEntities<SportClubDefaultInterface>()
    );
}
