import {Injectable} from '@angular/core';
import {
    AbstractEntityRepositoryServices,
} from "@ypa/data-access/abstract-repository-services";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from 'rxjs';
import {environment} from "@ypa/constants/environments";
import {
    BaseSportClubDefaultInterface,
    SportClubDefaultInterface,
    SportClubDefaultParams
} from "@ypa/types/sport-club-default";
import {SportClubDefaultRepository} from "@ypa/state-management/shared/sport-club-default";
import {EntityListInterface} from "@ypa/types/base-entity";
;

@Injectable({providedIn: "root"})

export class SportClubDefaultService extends AbstractEntityRepositoryServices<SportClubDefaultInterface, BaseSportClubDefaultInterface, SportClubDefaultParams> {
    constructor(
        private http: HttpClient,
        private sportClubDefaultRepository: SportClubDefaultRepository
    ) {
        super(sportClubDefaultRepository);
    }

    protected createReq(form: Partial<SportClubDefaultInterface> | BaseSportClubDefaultInterface): Observable<SportClubDefaultInterface> {
        return this.http.post<SportClubDefaultInterface>(`${environment.apiUrl}/sport-club-default`, form);
    }

    protected getAllReq(): Observable<SportClubDefaultInterface[]> {
        return this.http.get<SportClubDefaultInterface[]>(`${environment.apiUrl}/sport-club-default`);
    }

    protected getByIdReq(id: number): Observable<SportClubDefaultInterface> {
        return this.http.get<SportClubDefaultInterface>(`${environment.apiUrl}/sport-club-default/${id}`);
    }

    protected getByReq(params: SportClubDefaultParams): Observable<SportClubDefaultInterface[]> {
        return this.http.get<SportClubDefaultInterface[]>(`${environment.apiUrl}/sport-club-default`, {params: params as {}});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/sport-club-default/${id}`);
    }

    protected updateReq(id: number, form: Partial<SportClubDefaultInterface>): Observable<SportClubDefaultInterface> {
        return throwError(() => new Error('Update form club default is not implemented'));
    }

    protected updateListReq(list: EntityListInterface<SportClubDefaultInterface>): Observable<EntityListInterface<SportClubDefaultInterface>> {
        throw new Error("Method not implemented.");
    }
}
