import {Injectable} from '@angular/core';
import {ToastController, ToastOptions} from '@ionic/angular';
import {checkmarkCircleOutline} from 'ionicons/icons';
import {alertCircleOutline} from 'ionicons/icons';

@Injectable({providedIn: "root"})
export class StatusToastService {

    private toastItem: any;

    protected readonly toastConfig: ToastOptions = {
        animated: true,
        duration: 5000,
        cssClass: 'ypa-toast',
        buttons: [
            {
                text: 'Dismiss',
                role: 'cancel'
            }
        ],
        position: 'top',
    }

    constructor(
        private toastController: ToastController
    ) {
    }

    statusToast(config?: ToastOptions) {
        this.toastItem = this.toastController.create({
            ...this.toastConfig,
            ...config
        }).then((toastData) => {
            toastData.present().then();
        });
    }

    statusToastSuccess(config?: ToastOptions) {
        this.toastConfig.message = "Success!";
        this.toastConfig.cssClass = "ypa-toast--success";
        this.toastConfig.icon = checkmarkCircleOutline as any;
        this.statusToast(config);
    }

    statusToastError(config?: ToastOptions) {
        this.toastConfig.message = "Something going wrong";
        this.toastConfig.cssClass = "ypa-toast--error";
        this.toastConfig.icon = alertCircleOutline as any;
        this.statusToast(config);
    }

    hideToast() {
        this.toastItem = this.toastController.dismiss();
    }
}
