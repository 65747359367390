import {Injectable} from '@angular/core';
import {
    AbstractEntityRepositoryServices,
} from "@ypa/data-access/abstract-repository-services";
import {
    BaseFormClubDefaultInterface,
    FormClubDefaultInterface,
    FormClubDefaultParams
} from "@ypa/types/form-club-default";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from 'rxjs';
import {FormClubDefaultRepository} from "@ypa/state-management/shared/form-club-default";
import {environment} from "@ypa/constants/environments";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})

export class FormClubDefaultService extends AbstractEntityRepositoryServices<FormClubDefaultInterface, BaseFormClubDefaultInterface, FormClubDefaultParams> {
    constructor(
        private http: HttpClient,
        private formClubDefaultRepository: FormClubDefaultRepository
    ) {
        super(formClubDefaultRepository);
    }

    protected createReq(form: Partial<FormClubDefaultInterface> | BaseFormClubDefaultInterface): Observable<FormClubDefaultInterface> {
        return this.http.post<FormClubDefaultInterface>(`${environment.apiUrl}/form-club-default`, form);
    }

    protected getAllReq(): Observable<FormClubDefaultInterface[]> {
        return this.http.get<FormClubDefaultInterface[]>(`${environment.apiUrl}/form-club-default`);
    }

    protected getByIdReq(id: number): Observable<FormClubDefaultInterface> {
        return this.http.get<FormClubDefaultInterface>(`${environment.apiUrl}/form-club-default/${id}`);
    }

    protected getByReq(params: FormClubDefaultParams): Observable<FormClubDefaultInterface[]> {
        return this.http.get<FormClubDefaultInterface[]>(`${environment.apiUrl}/form-club-default`, {params: params as {}});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/form-club-default/${id}`);
    }

    protected updateReq(id: number, form: Partial<FormClubDefaultInterface>): Observable<FormClubDefaultInterface> {
        return throwError(() => new Error('Update form club default is not implemented'));
    }

    protected updateListReq(list: EntityListInterface<FormClubDefaultInterface>): Observable<EntityListInterface<FormClubDefaultInterface>> {
        throw new Error("Method not implemented.");
    }
}
