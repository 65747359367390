import {Injectable} from '@angular/core';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {
    UserDashboardMetricClubDefaultInterface,
    UserDashboardMetricClubDefaultParams
} from "@ypa/types/user-dashboard-metric-club-default";

const name = 'USER_DASHBOARD_METRIC_CLUB_DEFAULT';

@Injectable({
    providedIn: 'root'
})
export class UserDashboardMetricClubDefaultRepository extends AbstractEntityRepository<UserDashboardMetricClubDefaultInterface, UserDashboardMetricClubDefaultParams> {
    protected readonly store = createStore(
        {name},
        withEntities<UserDashboardMetricClubDefaultInterface>()
    );
}
