import {Injectable} from '@angular/core';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {
    MetricClubDefaultParams,
    MetricClubDefaultInterface
} from "@ypa/types/metric-club-default";

const name = 'METRIC_CLUB_DEFAULT';

@Injectable({
    providedIn: 'root'
})
export class MetricClubDefaultRepository extends AbstractEntityRepository<MetricClubDefaultInterface, MetricClubDefaultParams> {
    protected readonly store = createStore(
        {name},
        withEntities<MetricClubDefaultInterface>()
    );
}
