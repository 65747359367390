import {Injectable} from '@angular/core';
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {ControlsOf, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";
import {FormClubDefaultInterface} from "@ypa/types/form-club-default";

@Injectable()
export class FormClubDefaultFormService extends AbstractFormService<FormClubDefaultInterface> {
    getForm(params: Partial<FormClubDefaultInterface>): FormGroup<ControlsOf<Partial<FormClubDefaultInterface>>> {

        let form = {};

        if (params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        return this.fb.group({
            ...form,
            ...{
                clubId: this.fb.control(params.clubId ? params.clubId : null, [Validators.required]),
                formId: this.fb.control(params.formId ? params.formId : null, [Validators.required])
            }
        });
    }
}
