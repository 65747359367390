import {Injectable} from "@angular/core";
import {AbstractErrorInterceptor, OvdalError} from "@ypa/ovdal/ovdal-errors";
import {StatusToastService} from "@ypa/data-access/status-toast";

@Injectable()
export class ErrorInterceptor extends AbstractErrorInterceptor {

    constructor(
        private statusToastService: StatusToastService,
    ) {
        super();
    }

    protected showError(error: OvdalError) {
        this.statusToastService.statusToastError({message: error.message});
    }
}
